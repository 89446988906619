import { Dialog, Disclosure, Transition } from '@headlessui/react'

const MenuModal = ({ month, year, data, isOpen, setIsOpen }) => {
    const sortedData = [...data].sort((a, b) => new Date(a.date) - new Date(b.date));
    return (
        <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="relative z-50 !p-0">
            <div className="fixed inset-0 bg-black/30 p-0" aria-hidden="true" />
            <div className="fixed inset-0 flex w-screen items-center justify-center h-full">
                <Dialog.Panel className="mx-auto pt-0  w-screen rounded-xl bg-white p-5 min-h-[540px] max-h-[640px] overflow-y-auto fixed bottom-0">
                    <Dialog.Title className={'capitalize text-18 font-semibold sticky top-0 bg-white py-3'}>
                        Menu for {month}, {year}
                    </Dialog.Title>
                    <div className='w-full'>
                        {sortedData.map((day) => (
                            <Disclosure as="div" className="mt-2" key={day._id}>
                                <Disclosure.Button className="flex w-full justify-between rounded-lg bg-orange-100 px-4 py-2 text-left text-sm font-medium text-orange-900 hover:bg-orange-200 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/75">
                                    {new Date(day.date).toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
                                </Disclosure.Button>

                                <Transition
                                    enter="transition duration-100 ease-out"
                                    enterFrom="transform scale-95 opacity-0"
                                    enterTo="transform scale-100 opacity-100"
                                    leave="transition duration-75 ease-out"
                                    leaveFrom="transform scale-100 opacity-100"
                                    leaveTo="transform scale-95 opacity-0"
                                >
                                    <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm">
                                        <div className='grid grid-cols-1 md:grid-cols-3 gap-4 my-2'>
                                            <div>
                                                <p className='text-14 font-semibold'>Breakfast</p>
                                                <ul className='list-disc pl-4'>
                                                    {day.breakfast.map((item, i) => (
                                                        <li key={i} className='text-14 font-normal capitalize'>{item}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                            <div>
                                                <p className='text-14 font-semibold'>Lunch</p>
                                                <ul className='list-disc pl-4'>
                                                    {day.lunch.map((item, i) => (
                                                        <li key={i} className='text-14 font-normal capitalize'>{item}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                            <div>
                                                <p className='text-14 font-semibold'>Dinner</p>
                                                <ul className='list-disc pl-4'>
                                                    {day.dinner.map((item, i) => (
                                                        <li key={i} className='text-14 font-normal capitalize'>{item}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </Disclosure.Panel>
                                </Transition>
                            </Disclosure>
                        ))}
                    </div>
                </Dialog.Panel>
            </div>
        </Dialog>
    )
}

export default MenuModal;