import React, { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { checkOptingStatus, optMeal } from "../../redux/actions/MealActions";
import toast from "react-hot-toast";

const timeForMeals = {
  breakfast: "00:00",
  lunch: "00:00",
  dinner: "13:00",
};

const today = new Date();
const year = today.getFullYear();
const month = String(today.getMonth() + 1).padStart(2, "0"); // Adding 1 to get the correct month and padding with zero if necessary
const day = String(today.getDate()).padStart(2, "0"); // Padding with zero if necessary

const formattedDate = `${year}-${month}-${day}`;

function Slider({ todaysMeals }) { 
  const dispatch = useDispatch();
  const { planID, planValidity } = useSelector(
    (state) => state?.reducer?.profile,
    shallowEqual
  );
  const optedDate = useSelector(
    (state) => state?.reducer?.optedDate,
    shallowEqual
  );
  const [optedData, setOptedData] = useState({
    breakfast: null,
    lunch: null,
    dinner: null,
  });
  const [orderOtp, setOrderOtp] = useState({
    breakfast: null,
    lunch: null,
    dinner: null,
  });

  function isTimeAfter(referenceTime) {
    // Create a new Date object with the current date and reference time
    const currentDate = new Date();
    const referenceDate = new Date(optedDate);
    referenceDate.setHours(referenceTime.split(":")[0]);
    referenceDate.setMinutes(referenceTime.split(":")[1]);
    referenceDate.setSeconds(0);

    // Check if the current time is after the reference time
    return currentDate.getTime() > referenceDate.getTime();
  }

  const optAMeal = async (obj, mealType, orderType) => {
    if (process.env.REACT_APP_FEATURE_FLAG !== "true") {
      // !document.getElementById(`${mealType}-timer`).innerText.includes('up!')
      if (!isTimeAfter(timeForMeals[mealType]) || optedDate !== formattedDate) {
        const mealIdArray = obj?.map((data) => {
          return { mealId: data?._id };
        });
        const { success, message = null } = await dispatch(
          optMeal({ mealIdArray, mealType, orderType, optDate: optedDate })
        );

        if (success) {
          await optStatusCheck(mealType);
          switch (orderType) {
            case "optIn": {
              toast.success("Meal opted!");
              break;
            }

            case "optOut": {
              toast.success("Meal opted out!");
              break;
            }

            default:
          }
        } else {
          toast.error(message ?? "Meal not opted!");
        }
      } else {
        toast.error(`Time exceeded, can't ${orderType} now!`);
      }
    }
  };

  // const optStatusCheck = async (mealType) => {
  //   const { optType, orderOtp, success } = await dispatch(checkOptingStatus({ mealType, optDate: optedDate }));
  //   if (success && optType) {
  //     setOptedData(prevState => ({ ...prevState, [mealType]: optType }));
  //     setOrderOtp(prevState => ({ ...prevState, [mealType]: orderOtp }));
  //   }
  // }

  const optStatusCheck = async (mealType) => {
    const { optType, orderOtp, success } = await dispatch(
      checkOptingStatus({ mealType, optDate: optedDate })
    );
    if (success) {
      if (optType) {
        setOptedData((prevState) => ({
          ...prevState,
          [mealType]: optType,
        }));
      }

      if (orderOtp) {
        setOrderOtp((prevState) => ({
          ...prevState,
          [mealType]: orderOtp,
        }));
      }
    }
  };

  function startCountdown(timeoutHour, timeoutMinute, elementId) {
    const timerElement = document.getElementById(elementId);

    // Calculate the target time
    const now = new Date();
    const targetTime = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      timeoutHour,
      timeoutMinute,
      0
    );

    // Update the timer every second
    const interval = setInterval(updateTimer, 1000);

    function updateTimer() {
      const currentTime = new Date().getTime();
      const targetDate = new Date(optedDate);
      const timeDifference = targetDate.getTime() - currentTime;

      // Check if the target date has passed
      if (timeDifference < 0) {
        clearInterval(interval);
        timerElement.innerHTML = "Time's up!";
        return;
      }

      // Calculate days, hours, minutes, and seconds
      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

      // Format the time and update the timer element
      const formattedTime = `${days.toString().padStart(2, "0")} Days, ${hours
        .toString()
        .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
          .toString()
          .padStart(2, "0")}`;

      timerElement.innerHTML = `⏰ <span class="font-bold">${formattedTime || "Opt till 11PM"
        }</span> left`;
    }
  }

  const clearCountdownTimer = (mealType) => {
    const timerElement = document.getElementById(`${mealType}-timer`);
    if (timerElement) {
      const intervalId = timerElement.dataset.intervalId;
      clearInterval(intervalId);
      timerElement.innerHTML = "";
      delete timerElement.dataset.intervalId;
    }
  };

  function startCountdownTimer(mealType) {
    let timeoutHour, timeoutMinute;

    switch (mealType) {
      case "breakfast":
        timeoutHour = 0;
        timeoutMinute = 0;
        break;
      case "lunch":
        timeoutHour = 0;
        timeoutMinute = 0;
        break;
      case "dinner":
        timeoutHour = 13;
        timeoutMinute = 0;
        break;
      default:
        return;
    }

    const timerElement = document.getElementById(`${mealType}-timer`);
    if (timerElement) {
      const intervalId = startCountdown(
        timeoutHour,
        timeoutMinute,
        `${mealType}-timer`
      );
      timerElement.dataset.intervalId = intervalId;
    }
  }

  useEffect(() => {
    setOrderOtp({ breakfast: null, lunch: null, dinner: null });
    setOptedData({ breakfast: null, lunch: null, dinner: null });

    setTimeout(() => {
      Object.keys(todaysMeals).forEach((mealType) => {
        optStatusCheck(mealType);
        startCountdownTimer(mealType);
      });
    }, 200);

    return () => {
      Object.keys(todaysMeals).forEach((mealType) => {
        clearCountdownTimer(mealType);
      });
    };
  }, [todaysMeals]);

  let swiperItems = Object.keys(todaysMeals).map((key) => (
    <div
      className={`w-full border-solid border-l-4 ${optedData[key] !== "optIn" ? "border-l-black" : "border-l-green-500"
        } border-l-solid border-grey-200 border rounded-md mb-4 p-3 shadow-md`}
      key={key + Math.random()}
    >
      <h3
        className={`text-14 font-semibold text-left capitalize text-grey-500`}>
        {key} {!planID ? (key === 'breakfast' ? '₹90' : '₹150') : null}
      </h3>
      <h4 className="text-left text-blackAccent text-18 mb-4 font-semibold">
        {todaysMeals[key]?.map((data, index) => (
          <>
            {data?.name}
            {Object.keys(todaysMeals)?.length - 1 === index ? "" : ", "}
          </>
        ))}
      </h4>
      <div className="text-18 text-left mb-4">
        {optedDate === formattedDate && optedData[key] === null ? (
          <div id={`${key}-timer`} className="flex flex-row gap-x-1 text-14"></div>
        ) : optedDate >= formattedDate && orderOtp[key] ? (
          <div className="flex flex-col">
            <small>OTP</small>
            <span className="font-bold text-16 text-green-500">{orderOtp[key]}</span>
          </div>
        ) : null} 
      </div>
      <div className="flex items-center justify-start gap-4">
        <button
          className={`px-6 py-3 rounded-full border-[2px] font-bold text-white text-14 bg-orange-10 ${isTimeAfter(timeForMeals[key]) && optedDate === formattedDate ? 'opacity-50' : ''}`}
          onClick={() => optAMeal(todaysMeals[key], key, planID !== null && new Date(planValidity?.nextRenewalDate) >= new Date() ? 'optOut' : 'optIn')}
                   disabled={isTimeAfter(timeForMeals[key]) && optedDate === formattedDate}
        >
          {planID !== null && new Date(planValidity?.nextRenewalDate) >= new Date() ? 'Opt Out' : 'Opt In'}
        </button>
      </div>
    </div>
  ));

  return <div className="w-full flex flex-col mb-4">{swiperItems}</div>;
}

export default Slider;