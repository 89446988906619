import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LandingPage from "../../pages/LandingPage/LandingPage";
import SignUpPage from "../../pages/SignUpPage/SignUpPage";
import HomePage from "../../pages/HomePage/HomePage";
import MealsPage from "../../pages/MealsPage/MealsPage";
import InvoicePage from "../../pages/InvoicePage/InvoicePage";
import ProfilePage from "../../pages/ProfilePage/ProfilePage";
import Navbar from "../BottomNavigation/Navbar";
import WalletPage from "../../pages/WalletPage/WalletPage";
import CancelPage from "../../pages/PaymentStatus/Cancel";
import SuccessPage from "../../pages/PaymentStatus/Success";
import AlaCartePage from "../../pages/AlaCartePage/AlaCartePage";
import { Navigate } from "react-router-dom";

function Navigation() {
  const isAuthenticated = () => {
    // Retrieve auth token from cookie
    const authToken = document.cookie.replace(
      /(?:(?:^|.*;\s*)auth_token\s*=\s*([^;]*).*$)|^.*$/,
      "$1"
    );
    // Check if authToken exists
    return authToken !== "";
  };
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/" element={<LandingPage />} /> */}
        <Route
          path="/"
          element={isAuthenticated() ? <Navigate to="/home" /> : <LandingPage />}
        />
        <Route path="/sign-up" element={<SignUpPage />} />
        <Route
          path="/home"
          element={isAuthenticated() ? <HomePage /> : <Navigate to="/" />}
        />
        <Route
          path="/meals"
          element={isAuthenticated() ? <MealsPage /> : <Navigate to="/" />}
        />
        <Route
          path="/invoices"
          element={isAuthenticated() ? <InvoicePage /> : <Navigate to="/" />}
        />
        <Route
          path="/profile"
          element={isAuthenticated() ? <ProfilePage /> : <Navigate to="/" />}
        />
        <Route
          path="/wallet"
          element={isAuthenticated() ? <WalletPage /> : <Navigate to="/" />}
        />
        <Route path='/success' element={<SuccessPage />} />
        <Route path='/cancel' element={<CancelPage />} />
        <Route
          path="/ala-carte"
          element={isAuthenticated() ? <AlaCartePage /> : <Navigate to="/" />}
        />
      </Routes>
      {!['/success', '/cancel', '/sign-up'].includes(window.location.pathname) || window.location.pathname !== '/' ? <Navbar /> : null}
    </BrowserRouter>
  );
}

export default Navigation;
