import { fetchUserProfile, fetchWalletBalance, fetchWalletHistory, payRazorpay, razorpayCallBack, updateProfile } from "../../services/api/account";
import { getCookie } from "../../services/http.service";
import {jwtDecode} from 'jwt-decode';

const SET_TOKEN = 'SET_TOKEN';
export const setToken = (payload) => {
    return async (dispatch, getState) =>
        dispatch({
            type: SET_TOKEN,
            payload
        })
}

const FETCH_USER_PROFILE = 'FETCH_USER_PROFILE';
export const setUserProfileData = payload => async (dispatch) => {
    dispatch({
        type: FETCH_USER_PROFILE,
        payload
    })
}

// set token and profile id
export const setUserToken = () => async (dispatch) => {
    const cookie = getCookie('auth_token')
    dispatch(setToken(cookie));
}

// access profile data
export const getUserProfile = () => async (dispatch) => {
    const { data: { data } } = await fetchUserProfile();
    await dispatch(setUserProfileData(data));
}

// update profile data
export const updateUserProfile = (updatedData) => async (dispatch) => {
    const apiResponse = await updateProfile(updatedData);
    return apiResponse;
}

export const getWalletBalance = () => async (dispatch) => {
    const { data } = await fetchWalletBalance();
    await dispatch({ type: 'SET_WALLET_BALANCE', payload: data?.data })
}

export const getWalletHistory = () => async (dispatch) => {
    const { data } = await fetchWalletHistory();
    await dispatch({ type: 'SET_WALLET_HISTORY', payload: data?.data })
}

// associate plans to user
export const createRazorpayPayment = (amount, transactionId, metaInformation) => async () => {
    // paymentType : wallet reload, plan purchase, plan renewal
    const { data } = await payRazorpay(amount, transactionId);
    const decode = jwtDecode((getCookie("auth_token")))

    const options = {
        key: process.env.REACT_APP_RZP_KEY_ID,
        currency: "INR",
        amount: amount,
        name: "The Basic",
        description: metaInformation?.description,
        // image: logo,
        order_id: data.id,
        handler: async function (response) {
            const { data } = await razorpayCallBack(
                transactionId,
                metaInformation?.planId ?? null,
                metaInformation?.paymentType,
                metaInformation?.isWallet ?? false,
                response.razorpay_order_id,
                response.razorpay_payment_id,
                response.razorpay_signature,
                amount);

            if (data.success) {
                window.location.href = `/success?redirectionURL=${metaInformation?.redirectionURL}`
            } else {
                window.location.href = `/cancel?redirectionURL=${metaInformation?.redirectionURL}`
            }
        },
        prefill: {
            "name": decode?.user?.name,
            "email": decode?.user?.email
        },
        notes: {
            "address": `G-166, Gajapati Nagar, Bhubaneswar, Odisha - 751005`
        },
        theme: {
            "color": "#67c3ec"
        }
    };

    const paymentObject = new window.Razorpay(options);

    paymentObject.open();
}
