import React, { useEffect, useState } from "react";
import SolidButton from "../../components/Buttons/SolidButton";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateUserProfile } from "../../redux/actions/TenantActions";
import { getCookie } from "../../services/http.service";
import { fetchPlans } from "../../services/api/account";

function SignUpPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedPG, setSelectedPG] = useState('');
  const [roomNumber, setRoomNumber] = useState('');

  const submitForm = async () => {
    const { data: { success } } = await dispatch(updateUserProfile({
      selectedPG,
      roomNumber
    }));

    if (success) {
      navigate('/home');
    } else {
      alert('Something went wrwong.');
    }
  }

  useEffect(() => {
    const cookie = getCookie('isExistingUser') === 'true';
    if (cookie) {
      window.location.href = '/home';
    }
  }, []);

  return (
    <div className="h-screen min-h-[650px] px-2 py-8">
      <h2 className="text-[1.75rem] text-center font-semibold text-blackAccent relative">
        Sign Up
        <button className="absolute text-[1rem] right-0 top-[50%] translate-y-[-50%] text-orangeAccent font-normal" onClick={() => navigate("/")}>
          Login
        </button>
      </h2>

      <div className="p-8">
        <select
          className="bg-[#F6F6F6] border-[#E8E8E8] border-[2px] w-[100%] px-4 py-2 outline-none rounded-lg text-blackAccent h-[48px] mb-6"
          onChange={(e) => setSelectedPG(e.target.value)}
        >
          <option selected disabled value={""}>
            Select PG
          </option>
          <option value={"662b0f9b29bdd400088e0c8e"}>
            G-16
          </option>
          <option value={"662b0fc329bdd400088e0c9b"}>
            G-11
          </option>
          <option value={"662b0fd529bdd400088e0ca4"}>
            G-746
          </option>
          <option value={"662b0fde29bdd400088e0ca7"}>
            G-744
          </option>
          <option value={"65eb866043a3542900405230"}>
            G-166
          </option>
          <option value={"65eb86a343a354290040524d"}>
            G-189
          </option>
        </select>
        <input
          className="border-2 w-full px-4 py-2 outline-none rounded-lg text-blackAccent h-[48px] mb-6"
          placeholder="Room Number"
          onChange={(e) => setRoomNumber(e.target.value)}
        />
      </div>

      <div className="p-8">
        <SolidButton
          text={"Complete Signup"}
          onClick={() => submitForm()}
          className={"w-full"}
        />
      </div>
    </div>
  );
}

export default SignUpPage;
