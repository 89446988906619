import axios from "axios";

// BASE URL - LOCAL & CLOUD
const baseURL = process.env.REACT_APP_API_HOST;

// URL function returning the url
function URL(url) {
    return `${baseURL}/${url}`;
}

export function getCookie(name) {
    function escape(s) { return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1'); }
    var match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
    return match ? match[1] : null;
}

export function setCookie(name, value, options = {}) {
    if (typeof value === 'object') {
        value = JSON.stringify(value);
    }
    let cookieString = encodeURIComponent(name) + '=' + encodeURIComponent(value);

    // Set expiration if provided in options
    if (options.expires instanceof Date) {
        cookieString += '; expires=' + options.expires.toUTCString();
    }

    // Set max age if provided in options
    if (options.maxAge && Number.isInteger(options.maxAge)) {
        cookieString += '; max-age=' + options.maxAge;
    }

    // Set domain if provided in options
    if (options.domain) {
        cookieString += '; domain=' + options.domain;
    }

    // Set path if provided in options
    if (options.path) {
        cookieString += '; path=' + options.path;
    }

    // Set secure flag if provided in options
    if (options.secure) {
        cookieString += '; secure';
    }

    // Set HttpOnly flag if provided in options
    if (options.httpOnly) {
        cookieString += '; HttpOnly';
    }

    // Set SameSite attribute if provided in options
    if (options.sameSite) {
        cookieString += '; SameSite=' + options.sameSite;
    }

    document.cookie = cookieString;
}

export const refreshToken = async (id) => {
    var data = {
      id,
    };
  
    var config = {
      method: "post",
      url: `${baseURL}/user/refreshToken`,
      headers: {
        "Access-Control-Request-Headers": "*",
        "Content-Type": "application/json",
      },
      data: data,
    };
    return await axios(config);
  };

export const callBackend = async (method, url, data = {}, additionalHeader, isRootURL=false) => {
    let authToken = getCookie("auth_token");
    
    switch (method) {
        case "GET": {
            let config = {
                method: "get",
                url: URL(url),
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            };
            return await axios(config);
        }

        case "POST": {
            let config = {
                method: "post",
                url: isRootURL ? URL(url).replace('/v1','') : URL(url),
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                    ...additionalHeader,
                },
                data,
            };

            return await axios(config);
        }

        case "DELETE": {
            let config = {
                method: "delete",
                url: URL(url),
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
                data,
            };

            return await axios(config);
        }
        case "PUT": {
            let config = {
                method: "put",
                url: URL(url),
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
                data,
            };

            return await axios(config);
        }
        default:
            break;
    }
};