import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import SolidButton from "../Buttons/SolidButton";
import { initPurchase, stripePay } from "../../services/api/account";
import { createRazorpayPayment } from "../../redux/actions/TenantActions";

const WalletBalance = () => {
    const dispatch = useDispatch();
    const { walletBalance } = useSelector(state => state.reducer, shallowEqual);
    const [refillAmount, setRefillAmount] = useState(0);
    const addMoneyToWallet = async () => {
        const { data: { data, success } } = await initPurchase({ transactionType: 'WALLET_RELOAD', amount: refillAmount });
        if (success) {
            // const result = await stripePay(refillAmount, data, { isWallet: 'yes', planId: null, planName: null, redirectionURL: 'wallet' });

            // window.location.href = result?.data?.url;
            await dispatch(createRazorpayPayment(refillAmount, data, {isWallet: true, planId: null, planName: null, redirectionURL: 'wallet', paymentDescription: 'Wallet amount reload'}));
        }
    }

    return (
        <div className="flex flex-col w-full mb-4">
            <div className="flex items-center justify-center mb-3 w-full">
                <h3 className="text-black text-24 font-semibold flex flex-row items-center">
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <mask id="mask0_591_335" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="25">
                            <rect x="0.0213318" y="0.244385" width="24" height="24" fill="#D9D9D9" />
                        </mask>
                        <g mask="url(#mask0_591_335)">
                            <path d="M5.02133 21.2444C4.47133 21.2444 4.0005 21.0486 3.60883 20.6569C3.21717 20.2652 3.02133 19.7944 3.02133 19.2444V5.24438C3.02133 4.69438 3.21717 4.22355 3.60883 3.83188C4.0005 3.44022 4.47133 3.24438 5.02133 3.24438H19.0213C19.5713 3.24438 20.0422 3.44022 20.4338 3.83188C20.8255 4.22355 21.0213 4.69438 21.0213 5.24438H13.0213C11.838 5.24438 10.8755 5.61522 10.1338 6.35688C9.39217 7.09855 9.02133 8.06105 9.02133 9.24438V15.2444C9.02133 16.4277 9.39217 17.3902 10.1338 18.1319C10.8755 18.8736 11.838 19.2444 13.0213 19.2444H21.0213C21.0213 19.7944 20.8255 20.2652 20.4338 20.6569C20.0422 21.0486 19.5713 21.2444 19.0213 21.2444H5.02133ZM13.0213 17.2444C12.4713 17.2444 12.0005 17.0486 11.6088 16.6569C11.2172 16.2652 11.0213 15.7944 11.0213 15.2444V9.24438C11.0213 8.69438 11.2172 8.22355 11.6088 7.83188C12.0005 7.44022 12.4713 7.24438 13.0213 7.24438H20.0213C20.5713 7.24438 21.0422 7.44022 21.4338 7.83188C21.8255 8.22355 22.0213 8.69438 22.0213 9.24438V15.2444C22.0213 15.7944 21.8255 16.2652 21.4338 16.6569C21.0422 17.0486 20.5713 17.2444 20.0213 17.2444H13.0213ZM16.0213 13.7444C16.4547 13.7444 16.813 13.6027 17.0963 13.3194C17.3797 13.0361 17.5213 12.6777 17.5213 12.2444C17.5213 11.8111 17.3797 11.4527 17.0963 11.1694C16.813 10.8861 16.4547 10.7444 16.0213 10.7444C15.588 10.7444 15.2297 10.8861 14.9463 11.1694C14.663 11.4527 14.5213 11.8111 14.5213 12.2444C14.5213 12.6777 14.663 13.0361 14.9463 13.3194C15.2297 13.6027 15.588 13.7444 16.0213 13.7444Z" fill="#FE5935" />
                        </g>
                    </svg>
                    <span className="ml-2 text-24">
                        ₹{walletBalance ?? 0}
                    </span>
                </h3>
            </div>
            <div className="flex flex-row items-center justify-center shadow-sm p-4 bg-[#FFF4F0] border-2 border-solid border-orangeAccent rounded-md">
                <input type="tel" pattern="[0-9]*" defaultValue={refillAmount} max={5000} maxLength={4} placeholder="Enter refill amount" className="w-1/2 h-10 p-2 rounded-xl mr-2" onChange={e => setRefillAmount(parseInt(e.target.value, 10))} />
                <SolidButton text={'Add Money'} disabled={!refillAmount} onClick={() => addMoneyToWallet()} />
            </div>
        </div>
    )
}

export default WalletBalance;