import React, { useEffect, useRef, useState } from "react";
function WalletPage() {
    const [countdown, setCountdown] = useState(10);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (countdown > 0) {
                setCountdown(countdown - 1);
            }
        }, 1000);

        return () => clearTimeout(timer);
    }, [countdown]);

    useEffect(() => {
        if (countdown === 0) {
            window.location.href='/wallet';
        }
    }, [countdown]);

    return (
        <>
            <div className="min-h-screen p-4 pb-20">
                <div className="flex h-auto relative">
                    <div className="h-full w-full px-4 mb-6 flex flex-col items-start justify-center overflow-y-auto py-5">
                        <div className="mt flex flex-col w-full text-center">
                            <h3 className="text-20">Payment Cancelled!</h3>
                            <span className="text-14">Redirecting in {countdown}sec...</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default WalletPage;
