import React from "react";
import { useSelector } from "react-redux";

const IntroSection = () => {
    const {name} = useSelector(state => state.reducer.profile);
    return(
        <div className="flex items-center justify-between bg-[#FFF4F0] rounded-lg px-3 py-3 mb-5">
            <h3 className="text-orangeAccent text-1 font-semibold">
                👋🏻 Hi, {name}!
            </h3>
        </div>
    )
}

export default IntroSection;