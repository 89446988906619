import React, { useEffect, useRef, useState } from "react";

export default function WalletTxnItem({ transactionType, amount, txnDate, txnId, orderId }) {
    return (
      <div className="flex flex-col mb-10 w-full">
        <div className="flex flex-row justify-between w-full">
          <h2 className="text-black text-16 font-normal">
            {transactionType} {transactionType === 'ORDER' ? `#`+orderId?.substring(orderId?.length - 6) : null}
          </h2>
          <h2 className={`text-16 font-semibold capitalize ${txnId && txnId!=='' ? 'text-green-500':'text-black'}`}>₹{amount}/-</h2>
        </div>
        <div className="flex flex-row justify-between w-full">
          <p className="text-black text-14">
            {txnDate}
          </p>
          <p className={`text-14 ${txnId && txnId !== '' ? 'text-green-500':'text-yellow-600'}`}>{txnId && txnId !=='' ? `Txn Id: ${txnId}` : 'Transaction Not Completed'}</p>
        </div>
      </div>
    );
  }
  