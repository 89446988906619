import React from "react";

function SolidButton({ text, onClick, disabled = false, className }) {
  return (
    <button
      className={`${className} bg-orangeAccent w-auto h-10 px-4 rounded-full text-white font-semibold1 ${disabled ? 'opacity-40 cursor-not-allowed' : ''}`}
      onClick={()=>onClick()}
      disabled={false}
    >
      {text}
    </button>
  );
}

export default SolidButton;
