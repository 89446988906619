import React, { useEffect, useState } from "react";
import Navbar from "../../components/BottomNavigation/Navbar";
import PageTitle from "../../components/PageTitle/PageTitle";
import { addMonths, subMonths } from "date-fns";
import SolidButton from "../../components/Buttons/SolidButton";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getMonthlyMealSummary } from "../../redux/actions/MealActions";
import { initPurchase, stripePay } from "../../services/api/account";
import { createRazorpayPayment } from "../../redux/actions/TenantActions";

function Bar({ days, percentage, title }) {
  return (
    <div className="flex flex-col items-center">
      <div className="bg-[#F0F0F0] w-[20px] h-[300px] flex items-end rounded-full">
        <div
          className="bg-[#FFBFAB] w-[100%] text-12  rounded-full relative"
          style={{
            height: `${percentage}%`,
          }}
        >
          <p className="w-[32px] h-[32px] rounded-full bg-[#ffffff] border-[#FFBFAB] border-[2px] flex items-center justify-center absolute left-[50%] translate-x-[-50%] -top-8">
            {days}
          </p>
        </div>
      </div>
      <p className="text-[10px] font-semibold text-blackAccent">{title}</p>
    </div>
  );
}

function InvoicePage() {
  const dispatch = useDispatch();
  const { mealPlan, planValidity } = useSelector(state => state?.reducer?.profile, shallowEqual);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [breakfastCount, setBreakfastCount] = useState(0);
  const [lunchCount, setLunchCount] = useState(0);
  const [dinnerCount, setDinnerCount] = useState(0);
  const [alaCarteCount, setAlaCarteCount] = useState(0);

  const monthlyMealSummary = async () => {
    const { data, success } = await dispatch(getMonthlyMealSummary({ date: currentMonth }));
    if (success && data?.length) {
      data?.map((d) => {
        switch (d?.mealType) {
          case "breakfast": {
            setBreakfastCount(d?.count);
            break;
          }

          case "lunch": {
            setLunchCount(d?.count);
            break;
          }

          case "dinner": {
            setDinnerCount(d?.count);
            break;
          }

          case "alaCarte": {
            setAlaCarteCount(d?.count);
            break;
          }

          default: {
            setBreakfastCount(0);
            setLunchCount(0);
            setDinnerCount(0);
            setAlaCarteCount(0);
          }
        }
      })
    } else {
      setBreakfastCount(0);
      setLunchCount(0);
      setDinnerCount(0);
      setAlaCarteCount(0);
    }
  }

  const changeMonth = async (type) => {
    if (type === 'sub') {
      setCurrentMonth(subMonths(currentMonth, 1))
    } else {
      setCurrentMonth(addMonths(currentMonth, 1))
    }
  }

  const today = new Date();
  const hidePayButton = new Date(planValidity?.nextRenewalDate)?.getTime() > today?.getTime() && planValidity?.planActivationDate !== null;

  const packagePurchase = async () => {
    const { data: { data, success } } = await initPurchase({ transactionType: 'SUBSCRIPTION', amount: mealPlan?.price });
    if (success) {
      // const result = await stripePay(mealPlan?.price, data, { isWallet: false, planId: mealPlan?._id, planName: mealPlan?.name });

      // window.location.href = result?.data?.url;
      await dispatch(createRazorpayPayment(mealPlan?.price, data, {isWallet: false, planId: mealPlan?._id, planName: mealPlan?.name, redirectionURL: 'invoices', paymentDescription: 'Meal plan renewal'}));
    }
  }
  useEffect(() => {
    monthlyMealSummary();
  }, [currentMonth]);

  useEffect(() => {
    monthlyMealSummary();
  }, []);

  return (
    <>
      <div className="min-h-screen px-8 py-4 pb-20">
        <PageTitle title={"Invoice"} />
        <div className="flex items-center justify-between bg-[#FFF4F0] border-[2px] border-orangeAccent rounded-lg px-2 py-2 mb-10">
          <button
            className="flex p-2"
            onClick={() => changeMonth('sub')}
          >
            <span class="material-symbols-rounded text-orangeAccent">
              arrow_back_ios
            </span>
          </button>
          <h2 className="flex items-center text-blackAccent font-semibold gap-2 ">
            <span class="material-symbols-rounded text-orangeAccent">
              calendar_month
            </span>
            {currentMonth?.toUTCString().slice(8, 16)}
          </h2>
          <button
            className="flex p-2"
            onClick={() => changeMonth('add')}
          >
            <span class="material-symbols-rounded text-orangeAccent">
              arrow_forward_ios
            </span>
          </button>
        </div>

        <div className="px-6 w-full flex flex-col items-center">
          <div className="flex justify-evenly mb-4 w-full mt-4">
            <Bar days={breakfastCount} percentage={(breakfastCount / 100) * 100} title={"Breakfast"} />
            <Bar days={lunchCount} percentage={(lunchCount / 100) * 100} title={"Lunch"} />
            <Bar days={dinnerCount} percentage={(dinnerCount / 100) * 100} title={"Dinner"} />
            <Bar days={alaCarteCount} percentage={(alaCarteCount / 100) * 100} title={"A-La Carte"} />
          </div>

          <h1 className="text-center text-blackAccent font-extrabold text-24">
            ₹ {mealPlan?.price} /-
          </h1>
          <SolidButton text={hidePayButton ? `Paid on ${new Date(planValidity?.renewalDate)?.toLocaleDateString()}` : "Pay Now"} className={`w-full mt-4 ${hidePayButton ? '!bg-green-500 !opacity-100' : ''}`} disabled={hidePayButton} onClick={() => packagePurchase()} />
          <p className="text-18 text-center mt-6">Active Plan: <br /><strong className="text-orange-10">{mealPlan?.name}</strong></p>
          <p className="mt-1 text-16">Next renewal on <strong>{new Date(planValidity?.nextRenewalDate)?.toLocaleDateString()}</strong></p>
        </div>
      </div>
      {/* <Navbar /> */}
    </>
  );
}

export default InvoicePage;