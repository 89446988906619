import React, { useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle/PageTitle";
import Slider from "../../components/Slider/Slider";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { createRazorpayPayment, getUserProfile, updateUserProfile } from "../../redux/actions/TenantActions";
import IntroSection from "../../components/HomePage/IntroSection";
import { getTodaysMeal, setOptedDate } from "../../redux/actions/MealActions";
import { fetchPlans, initPurchase, stripePay } from "../../services/api/account";
import SolidButton from "../../components/Buttons/SolidButton";
import { fetchMonthlyMenu } from "../../services/api/meals";
import MenuModal from "../../components/HomePage/MenuModal";

const yr = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];

function HomePage() {
  const dispatch = useDispatch();
  const { todaysMeals, profile: { mealPlan, planValidity } } = useSelector(state => state?.reducer, shallowEqual);
  const today = new Date().toISOString().split("T")[0];
  const [plansList, setPlansList] = useState([]);
  const [monthlyMenu, setMonthlyMenu] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(mealPlan?._id ?? null);
  let [isOpen, setIsOpen] = useState(true)
  const [showPopup, setShowPopup] = useState(false)

  const getPlans = async () => {
    const { data: { data, success } } = await fetchPlans();
    if (success) {
      setPlansList(data);
    }
  }

  const getDateWiseMenu = async (date) => {
    dispatch(setOptedDate(date))
    await dispatch(getTodaysMeal(date));
  }

  const packagePurchase = async () => {
    const { price, name } = plansList?.filter((d) => d._id === selectedPlan)[0];

    const { data: { data, success } } = await initPurchase({ transactionType: 'SUBSCRIPTION', amount: price });
    if (success) {
      // const result = await stripePay(price, data, { isWallet: false, planId: selectedPlan, planName: name, redirectionURL: 'home' });

      // window.location.href = result?.data?.url;
      await dispatch(createRazorpayPayment(price, data, { isWallet: false, planId: selectedPlan, planName: name, redirectionURL: 'home', paymentDescription: 'Meal plan purchase' }));
    }
  }

  const getMenu = async () => {
    const year = new Date().getFullYear();
    const month = yr[new Date().getMonth()]
    const { data: { data } } = await fetchMonthlyMenu(month, year);
    setMonthlyMenu(data);
    setIsOpen(!isOpen);
  }

  const setUserProfile = async (updatedData) => {
    const response = await dispatch(updateUserProfile(updatedData));
    if (response) {
      window.location.href = '/'
    } else {
      alert("Failed to update profile.");
    }
  }

  useEffect(() => {
    dispatch(getUserProfile());
    dispatch(getTodaysMeal());
    getPlans();
  }, [])

  return (
    <>
      <div className="min-h-screen px-8 py-4 pb-20">
        <PageTitle title={"Dashboard"} />

        <IntroSection />
        <h4 className="text-blackAccent font-semibold text-16 w-full">
          Opt in for your upcoming meal
        </h4>
        <div className="w-full mt-2">
          <input type="date" placeholder="Choose date" defaultValue={today} onChange={(e) => getDateWiseMenu(e.target.value)} className="border p-2 w-full border-solid border-grey-500 rounded-md" />
        </div>
        {
          Object.keys(todaysMeals).length ? <><div className="flex flex-col items-center justify-between mb-4">


          </div><Slider todaysMeals={todaysMeals} /> </> : null
        }

        <small className="italic">Note: Meals offered subject to ingredient availability, market availability, cook availability, national holidays and weather conditions, with limits to ensure fair access for all tenants.</small>


        <div className="flex items-center justify-between my-5">
          <h4 className="text-blackAccent font-normal text-16">
            Your Active Meal Plan:<br /><strong>{mealPlan?.name ?? 'No Plan'}</strong>
          </h4>
        </div>

        <div className="flex flex-col items-center justify-between bg-[#FFF4F0] rounded-lg px-3 py-2 mb-5">
          <select
            className="bg-[#F6F6F6] border-[#E8E8E8] border-[2px] w-[100%] px-4 py-2 outline-none rounded-lg text-blackAccent h-[48px] mb"
            onChange={(e) => setSelectedPlan(e.target.value)}
          >
            <option value={''}>
              No Plan
            </option>
            {
              plansList?.map((d, i) => (
                <option value={d?._id} selected={mealPlan?._id === d?._id}>
                  ₹{d?.price}/mo {d?.name}
                </option>
              ))
            }
          </select>
          <SolidButton text={'Purchase Plan'} disabled={mealPlan?._id === selectedPlan} className={`w-full mt-4`} onClick={() => packagePurchase()} />
          <SolidButton text={'Show Monthly Menu'} className={`w-full mt-1 bg-transparent !text-orange-10 border-orange-10`} onClick={() => getMenu()} />
          <SolidButton
            text={'Switch to No Plan'}
            className={`w-full mt-1 bg-transparent !text-red-400 border-red-400`}
            onClick={() => {
                setSelectedPlan(null);
                setUserProfile({ mealPlan: null, planValidity: { planActivationDate: null, renewalDate: null, nextRenewalDate: null } })
            }}
          />
        </div>

        <small className="text-12"><strong>No Plan</strong> is one of our food plan options. Meal prices for this plan are higher and may fluctuate due to uncertain demand and minimal planning time.</small>

        <div>
          <button
            className="flex items-center justify-between w-[100%] px-3 py-4 rounded-lg"
            onClick={() => setShowPopup(true)}
          >
            <span className="flex items-center text-blackAccent font-medium text-[14px]">
              <span className="material-symbols-rounded text-orangeAccent mr-2">
                info
              </span>
              View Cancellation Policy
            </span>
            <span className="material-symbols-rounded text-orangeAccent">
              chevron_right
            </span>
          </button>

          {showPopup && (
            <div className="fixed inset-0 flex items-center justify-center z-50">
              <div className="absolute inset-0 bg-black opacity-50"></div>
              <div className="bg-white p-6 rounded-lg w-full max-w-md z-50 shadow-lg h-full max-h-[90%] absolute bottom-10">
                <h1 className="text-xl font-semibold mb-4">Cancellation Policy</h1>
                <p className="mb-2 text-14">We understand that plans can change. Our food management app allows tenants to cancel or opt for food on a daily basis, subject to the following conditions:</p>
                <ol className="list-decimal list-inside text-14">
                  <li className="mb-2">Cancellations or opt-ins must be made by 11:00 PM the day before the meal date.</li>
                  <li className="mb-2">Changes made after the cut-off time will not be processed for the next day.</li>
                  <li className="mb-2">Tenants are responsible for ensuring their selections are up-to-date.</li>
                  <li className="mb-2">No refunds will be issued for missed cancellations.</li>
                  <li className="mb-2">Stand-alone (Breakfast/Lunch/Dinner): 10 meal cancellations allowed</li>
                  <li className="mb-2">Breakfast + Lunch: 20 meal cancellations allowed</li>
                  <li className="mb-2">Breakfast + Lunch + Dinner: 30 meal cancellations allowed</li>
                  <li className="mb-2">In case of app malfunction, please contact support immediately.</li>
                  <li className="mb-2">Breakfast Opt-in allowed till previous day 11:00 PM</li>
                  <li className="mb-2">Lunch Opt-in allowed till previous day 11:00 PM</li>
                  <li className="mb-2">Dinner Opt-in allowed till same day 01:00 PM</li>
                </ol>
                <p className="my-2 text-10">We reserve the right to modify this policy at any time. Users will be notified of any changes via the app. Please note that these limits apply per package</p>
                <button
                  className="bg-orangeAccent text-white px-4 py-2 rounded-lg mt-5"
                  onClick={() => setShowPopup(false)}
                >
                  Close
                </button>
              </div>
            </div>
          )}
        </div>

        <button className="flex items-center justify-between w-[100%] px-3 py-4 rounded-lg">
          <span className="flex items-center text-blackAccent font-medium text-[14px]">
            <span class="material-symbols-rounded text-orangeAccent mr-2">
              chat
            </span>
            Write a weekly feedback
          </span>
          <span class="material-symbols-rounded text-orangeAccent">
            chevron_right
          </span>
        </button>
      </div>
      {monthlyMenu ? <MenuModal isOpen={isOpen} setIsOpen={() => setIsOpen(!isOpen)} month={yr[new Date().getMonth()]} year={new Date().getFullYear()} data={monthlyMenu} /> : null}
    </>
  );
}

export default HomePage;
