import React, { useState } from "react";
import food_product from "../../assets/images/food_product.png";
import google_sign_in from "../../assets/images/google_sign_in.png";
import SolidButton from "../../components/Buttons/SolidButton";
import { loginWithPassword } from "../../services/api/account";
import toast from "react-hot-toast";
import { setCookie } from "../../services/http.service";

function LandingPage() {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [agreeTerms, setAgreeTerms] = useState(false)

  const googleRedirect = () => {
    window.location.href = `${process.env.REACT_APP_API_HOST}/user/google/create-account`;
  }

  const signinWithPassword = async () => {
    const { data } = await loginWithPassword(email, password);
    // console.log(data)
    if (!data.success) {
      toast.error(data.message);
    } else {
      setCookie('auth_token', data.data.token, {
        maxAge: 31536000,
        httpOnly: false,
        domain: process.env.REACT_APP_APP_DOMAIN,
        path: "/"
      });
      setCookie('isExistingUser', data.data.isExistingUser, {
        maxAge: 31536000,
        httpOnly: false,
        domain: process.env.REACT_APP_APP_DOMAIN,
        path: "/"
      });
      window.location.href = '/sign-up';
    }
  }

  return (
    <div
      className="h-screen min-h-[650px] p-8"
    >
      <div
        className="h-[90%] flex flex-col
    items-center justify-center"
      >
        <div className="mb-2">
          <img
            className="w-[40%] m-auto"
            src={food_product}
            alt="Food Products"
          />
        </div>
        <h1 className="text-[32px] font-semibold">OneMirth</h1>
        <h3 className="text text-center mb-8 text-blackAccent">
          One stop food management for <br />
          tenants.
        </h3>
        {/* <div className="mt mb-2 flex flex-col items-center justify-center gap-3">
          <input placeholder="Enter your email address" type="email" className="rounded-sm border h-10 p-2 w-full" onChange={e => setEmail(e.target.value)} />
          <input placeholder="Enter your password" type="password" className="rounded-sm border h-10 p-2 w-full" onChange={e => setPassword(e.target.value)} />
          <SolidButton text={'Log in'} className={'w-full rounded-sm'} onClick={() => signinWithPassword()} />
        </div>
        <p>OR</p> */}
        <div className="mt mb-2 flex flex-col items-center justify-center gap-3">
          <div className="flex items-center">
            <input type="checkbox" id="terms" onChange={e => setAgreeTerms(e.target.checked)} />
            <label htmlFor="terms" className="ml-2">I agree to the <a href="https://thebasic.co.in/terms-and-conditions" className="text-blue-400">terms and conditions</a></label>
          </div>
          <button className="w-[60%] mt-2" onClick={() => googleRedirect()} disabled={!agreeTerms}>
            <img className="w-[100%]" src={google_sign_in} alt="Google Sign In" style={{opacity: !agreeTerms ? '0.5': '1'}}/>
          </button>
        </div>

      </div>

      <div className="h-[10%]">
        <p className="text-center text-[0.8rem] text-blackAccent mb-2">
          v1.0.0 | The Basic
        </p>
        <p className="text-[13px] flex flex-row flex-wrap items-center justify-center gap-x-2">
          <a href="https://thebasic.co.in/contact" className="text-blue-400">Contact Us</a> •
          <a href="https://thebasic.co.in/terms-and-conditions" className="text-blue-400">T&C</a> •
          <a href="https://thebasic.co.in/privacy-policy" className="text-blue-400">Privacy Policy</a> •
          <a href="https://thebasic.co.in/cancellation-policy" className="text-blue-400">Cancellation Policy</a> •
          <a href="https://thebasic.co.in/shipping-policy" className="text-blue-400">Shipping Policy</a>
        </p>
      </div>
    </div>
  );
}

export default LandingPage;
