import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

function Navbar() {
  const { profile: { planID } } = useSelector(state => state?.reducer, shallowEqual);
  return (
      <div className="fixed bg-blackAccent w-[100%] max-w-[520px] bottom-0 py-4 px-8 flex items-center justify-between z-50" >
        <NavLink
          to={"/home"}
          className={({ isActive, isPending }) =>
            isActive
              ? "text-orangeAccent flex flex-col items-center"
              : "text-white flex flex-col items-center"
          }
        >
          <span className="material-symbols-rounded">home</span>
          <h6 className="text-10">Home</h6>
        </NavLink>
        <NavLink
          to={"/ala-carte"}
          className={({ isActive, isPending }) =>
            isActive
              ? "text-orangeAccent flex flex-col items-center"
              : "text-white flex flex-col items-center"
          }
        >
          <span class="material-symbols-rounded">dinner_dining</span>
          <h6 className="text-10">Ala Carte</h6>
        </NavLink>

        <NavLink
          to={"/meals"}
          className={({ isActive, isPending }) =>
            isActive
              ? "text-orangeAccent flex flex-col items-center"
              : "text-white flex flex-col items-center"
          }
        >
          <span class="material-symbols-rounded">lists</span>
          <h6 className="text-10">Orders</h6>
        </NavLink>

        {
          planID ? <NavLink
            to={"/invoices"}
            className={({ isActive, isPending }) =>
              isActive
                ? "text-orangeAccent flex flex-col items-center"
                : "text-white flex flex-col items-center"
            }
          >
            <span className="material-symbols-rounded">contract</span>
            <h6 className="text-10">Invoices</h6>
          </NavLink> : <NavLink
            to={"/wallet"}
            className={({ isActive, isPending }) =>
              isActive
                ? "text-orangeAccent flex flex-col items-center"
                : "text-white flex flex-col items-center"
            }
          >
            <span className="material-symbols-rounded">wallet</span>
            <h6 className="text-10">Wallet</h6>
          </NavLink>
        }

        <NavLink
          to={"/profile"}
          className={({ isActive, isPending }) =>
            isActive
              ? "text-orangeAccent flex flex-col items-center"
              : "text-white flex flex-col items-center"
          }
        >
          <span className="material-symbols-rounded">person</span>
          <h6 className="text-10">Profile</h6>
        </NavLink>
      </div>
  );
}

export default Navbar;
