import { callBackend } from "../http.service";

// fetch user data
export const fetchTodaysMeal = async (dateString='') => {
    return await callBackend("GET", `user/get-today-meals?date=${dateString}`);
}
// opt a meal
export const optAMeal = async (data) => {
    return await callBackend("POST", `user/opt-meal`, data);
}

export const checkOptStatus = async (data) => {
    return await callBackend("POST", `user/get-opt-status`, data);
}

export const fetchUserMeals = async (data) => {
    return await callBackend("POST", `user/get-user-meals`, data);
} 

export const fetchMonthlyMealSummary = async (data) => {
    return await callBackend("POST", `user/get-monthly-meal-summary`, data);
} 

export const fetchMealList = async (data) => {
    return await callBackend("GET", `meal/get-meal-list?isAlaCarte=${data}`);
}

export const fetchMonthlyMenu = async (month, year) => {
    return await callBackend("GET", `meal/get-all-menu/${month}/${year}`);
}

export const cancelOrder = async (data) => {
    return await callBackend("POST", `user/cancel-order`, data);
}
