import React, { useEffect, useRef, useState } from "react";
import WalletTxnItem from "./WalletTransactionItem";
import { shallowEqual, useSelector } from "react-redux";

export default function WalletTransactionContainer() {
    const walletHistory = useSelector(state => state?.reducer?.walletHistory, shallowEqual);
    return (
      <div className="flex flex-col mt-4 w-full">
        {
            walletHistory?.map((data, index) => (
                <WalletTxnItem transactionType={data?.transactionType} amount={data?.amount} txnDate={new Date(data?.updatedAt).toLocaleDateString()} txnId={data?.txnID} key={data?._id} orderId={data?.orderId}/>
            ))
        }
      </div>
    );
  }
  