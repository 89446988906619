import React, { useEffect, useState } from "react";
import Navbar from "../../components/BottomNavigation/Navbar";
import PageTitle from "../../components/PageTitle/PageTitle";
import SolidButton from "../../components/Buttons/SolidButton";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile, setUserToken, updateUserProfile } from "../../redux/actions/TenantActions";
import toast from "react-hot-toast";

function ProfilePage() {
  const dispatch = useDispatch();
  const { name, email, selectedPG, roomNumber } = useSelector(
    (state) => state?.reducer?.profile
  );

  const [profileData, setProfileData] = useState({ name, email, selectedPG, roomNumber });

  const saveData = async () => {
    const { data: { success } } = await dispatch(updateUserProfile({
      selectedPG: profileData?.selectedPG,
      roomNumber: profileData?.roomNumber
    }));

    if (success) {
      toast.success("Profile updated!")
    } else {
      toast.error("Update failed!")
    }
  }

  useEffect(() => {
    dispatch(setUserToken());
    dispatch(getUserProfile());
  }, []);

  return (
    <>
      <div className="min-h-[650px] px-8 py-4 pb-20">
        <PageTitle title={"Profile"} />

        <div className="px-6">
          <label className="text-blackAccent mb-6  flex flex-col">
            Name
            <input
              defaultValue={profileData?.name || ''}
              className="bg-[#F6F6F6] border-[#E8E8E8] border-[2px] w-[100%] px-4 py-2 outline-none rounded-lg text-blackAccent h-[48px] mb-6
        "
              onChange={(e) => setProfileData(prevState => ({ ...prevState, name: e.target.value }))}
            />
          </label>

          <label className="text-blackAccent mb-6  flex flex-col">
            Email
            <input
              defaultValue={profileData?.email || ''}
              className="bg-[#F6F6F6] border-[#E8E8E8] border-[2px] w-[100%] px-4 py-2 outline-none rounded-lg text-blackAccent h-[48px] mb-6
        "
            />
          </label>

          <label className="text-blackAccent mb-6  flex flex-col">
            PG No.
            {/* <input
              defaultValue={selectedPG || ''}
              className="bg-[#F6F6F6] border-[#E8E8E8] border-[2px] w-[100%] px-4 py-2 outline-none rounded-lg text-blackAccent h-[48px] mb-6
             
        "
            /> */}
            <select
              className="bg-[#F6F6F6] border-[#E8E8E8] border-[2px] w-[100%] px-4 py-2 outline-none rounded-lg text-blackAccent h-[48px] mb-6"
              onChange={(e) => setProfileData(prevState => ({ ...prevState, selectedPG: e.target.value }))}
            >
              <option value={"662b0f9b29bdd400088e0c8e"} selected={profileData?.selectedPG === '662b0f9b29bdd400088e0c8e'}>
                G-16
              </option>
              <option value={"662b0fc329bdd400088e0c9b"} selected={profileData?.selectedPG === '662b0fc329bdd400088e0c9b'}>
                G-11
              </option>
              <option value={"662b0fd529bdd400088e0ca4"} selected={profileData?.selectedPG === '662b0fd529bdd400088e0ca4'}>
                G-746
              </option>
              <option value={"662b0fde29bdd400088e0ca7"} selected={profileData?.selectedPG === '662b0fde29bdd400088e0ca7'}>
                G-744
              </option>
              <option value={"65eb866043a3542900405230"} selected={profileData?.selectedPG === '65eb866043a3542900405230'}>
                G-166
              </option>
              <option value={"65eb86a343a354290040524d"} selected={profileData?.selectedPG === '65eb86a343a354290040524d'}>
                G-189
              </option>
            </select>
          </label>

          <label className="text-blackAccent mb-6  flex flex-col">
            Room No.
            <input
              defaultValue={profileData?.roomNumber || ''}
              className="bg-[#F6F6F6] border-[#E8E8E8] border-[2px] w-[100%] px-4 py-2 outline-none rounded-lg text-blackAccent h-[48px] mb-6
        "
              onChange={(e) => setProfileData(prevState => ({ ...prevState, roomNumber: e.target.value }))}
            />
          </label>
          <SolidButton text={"Logout"} onClick={()=>window.location.href='/'} className={'bg-transparent !border-orange-10 !text-black'}/>
          <SolidButton text={"Save"} onClick={()=>saveData()}/>
        </div>
      </div>
      
      <Navbar />
    </>
  );
}

export default ProfilePage;
