const initialState = {
    profileID: null,
    token: null,
    profile: {
        name: null,
        email: null,
        planID: null,
        selectedPG: null,
        roomNumber: null,
        planValidity: null,
        mealPlan: null
    },
    isLoading: false,

    todaysMeals: {},

    walletBalance: 0,
    walletHistory: [],

    optedDate: null
}

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case 'FETCH_USER_PROFILE': {
            return {
                ...state,
                profile: {
                    name: payload?.name,
                    email: payload?.email,
                    planID: payload?.mealPlan?._id ?? null,
                    selectedPG: payload?.selectedPG,
                    roomNumber: payload?.roomNumber,
                    mealPlan: payload?.mealPlan ?? null,
                    planValidity: payload?.planValidity
                },
                profileID: payload?._id
            }
        }

        case 'SET_TOKEN': {
            return {
                ...state,
                token: payload
            }
        }

        case 'TOGGLE_LOADING': {
            return {
                ...state,
                isLoading: payload
            }
        }

        case 'SET_TODAYS_MEALS': {
            return {
                ...state,
                todaysMeals: payload
            }
        }

        case 'SET_WALLET_BALANCE': {
            return {
                ...state,
                walletBalance: payload
            }
        }

        case 'SET_WALLET_HISTORY': {
            return {
                ...state,
                walletHistory: payload
            }
        }

        case 'SET_OPTDATE': {
            return {
                ...state,
                optedDate: payload
            }
        }

        default:
            return state;
    }
}

export default reducer;