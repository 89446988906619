import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from 'react-redux';
import { Toaster } from 'react-hot-toast';
import store from './redux/store';
import "./index.css";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store()}>
    <App />
    <Toaster
      position="bottom-center"
      reverseOrder={false}
      toastOptions={{
        background: '#111111',
        color: '#fff'
      }}
    />
  </Provider>
);
