import { useState } from "react";
import { checkOptStatus, fetchMealList, fetchMonthlyMealSummary, fetchTodaysMeal, fetchUserMeals, optAMeal } from "../../services/api/meals";

const SET_TODAYS_MEALS = 'SET_TODAYS_MEALS';
const SET_OPTDATE = 'SET_OPTDATE';
export const setTodaysMeals = (payload) => {
    return async (dispatch) => {
        dispatch({
            type: SET_TODAYS_MEALS,
            payload: payload
        })
    }
}

export const setOptedDate = (payload) => async (dispatch) => {
    await dispatch({
        type: SET_OPTDATE,
        payload: payload
    })
}

export const getTodaysMeal = (date='') => async (dispatch) => {
    const { data: { data } } = await fetchTodaysMeal(date);
    await dispatch(setTodaysMeals(data));
}

export const optMeal = (body) => async () => {
    const { data: {success, message} } = await optAMeal(body);
    
    return {success, message}
}

export const checkOptingStatus = (body) => async () => {
    const { data: {data, success} } = await checkOptStatus(body);
    
    return {
        optType: data[0]?.optType,
        orderOtp: data[0]?.orderOtp ?? null,
        success
    }
}

export const getUserMeals = (body) => async () => {
    const {data: {data, success}} = await fetchUserMeals(body);

    return {
        data,
        success
    }
}

export const getMonthlyMealSummary = (body) => async () => {
    const {data: {data, success}} = await fetchMonthlyMealSummary(body);

    return {
        data: data,
        success
    }
}

export const getMealList = (isAlaCarte) => async () => {
    const {data: {data, success}} = await fetchMealList(isAlaCarte);

    return {
        data,
        success
    }
}