import React, { useEffect, useRef, useState } from "react";
import {
  addMonths,
  startOfMonth,
  subMonths,
  getDaysInMonth,
  addDays,
} from "date-fns";
import PageTitle from "../../components/PageTitle/PageTitle";
import { useDispatch } from "react-redux";
import { getUserMeals } from "../../redux/actions/MealActions";
import toast from "react-hot-toast";
import { cancelOrder } from "../../services/api/meals";

function FoodItem({ mealName, mealCost, mealType, qty }) {
  return (
    <div className="flex mb-4">
      <div className="w-[64px] h-[64px] bg-orange-50 rounded-md p-3">
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="opacity-100">
          <mask id="mask0_560_1270" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
            <rect width="24" height="24" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_560_1270)">
            <path d="M4 21L2 19H22L20 21H4ZM3 18C3.1 17.7 3.23333 17.4167 3.4 17.15C3.56667 16.8833 3.76667 16.6333 4 16.4V9H3V7.5H4V6.75H3V5.25H4V4.5H3V3H10C10.55 3 11.0208 3.19583 11.4125 3.5875C11.8042 3.97917 12 4.45 12 5V5.25H21V6.75H12V7C12 7.55 11.8042 8.02083 11.4125 8.4125C11.0208 8.80417 10.55 9 10 9H8V15.1C8.23333 15.1333 8.46667 15.1833 8.7 15.25C8.93333 15.3167 9.15 15.4167 9.35 15.55C9.78333 14.4667 10.475 13.6042 11.425 12.9625C12.375 12.3208 13.425 12 14.575 12C16.075 12 17.3542 12.5125 18.4125 13.5375C19.4708 14.5625 20 15.8167 20 17.3V18H3ZM8 5.25H10V4.5H8V5.25ZM8 7.5H10V6.75H8V7.5ZM5.5 5.25H6.5V4.5H5.5V5.25ZM5.5 7.5H6.5V6.75H5.5V7.5ZM5.5 15.35C5.66667 15.2667 5.82917 15.2042 5.9875 15.1625C6.14583 15.1208 6.31667 15.0833 6.5 15.05V9H5.5V15.35Z" fill="#FE5935" />
          </g>
        </svg>
      </div>
      <div className="flex flex-col justify-between">
        <div className="px-2">
          <h3 className="text-[#343741] text-16 font-normal">
            {mealName}
          </h3>
          <h4 className="text-[#707070] text-14 font-semibold capitalize">{mealType}</h4>
        </div>
        <h5 className="text-[#343741] text-14 font-semibold px-2">₹{mealCost} x {qty}</h5>
      </div>
    </div>
  );
}

function MealsPage() {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [days, setDays] = useState([]);
  const [mealsData, setMealsData] = useState([]);
  const dateSelectorsRef = useRef("");
  const dispatch = useDispatch();

  const scroll = () => {
    const containerDiv = document?.getElementById('date-scroller');
    const targetDiv = document?.getElementById('date-selected');
    const targetOffset = targetDiv?.offsetTop - containerDiv?.offsetTop;
    containerDiv?.scrollTo({
      top: targetOffset - 180,
      behavior: 'smooth' // optional, adds smooth scrolling effect
    });
  }

  const handleDateChange = async (date) => {
    setSelectedDate(date);
    await fetchUserMealsByDate(date);
  }

  const fetchUserMealsByDate = async (date) => {
    const { data, success } = await dispatch(getUserMeals({ date: date ?? selectedDate }));

    if (success) {
      setMealsData(data);
      toast.success('Meals loaded!');
    } else {
      toast.error('Unable to load meals! Change date.');
    }

    scroll();
  }

  const markOrderCancel = async (orderId) => {
    const { success, message } = await cancelOrder(orderId );

    if (success) {
      await fetchUserMealsByDate(selectedDate); // Refresh the meals data
    } else {
      alert(`Failed to cancel order: ${message}`);
    }
  }

  useEffect(() => {
    let data = [];
    for (let i = 0; i < getDaysInMonth(currentMonth); i++) {
      data.push({
        day: i + 1,
        date: addDays(startOfMonth(currentMonth), i),
      });
    }
    setDays(data);
  }, [currentMonth]);

  useEffect(() => {
    fetchUserMealsByDate();
  }, []);

  return (
    <>
      <div className="h-full p-4 pb-20">
        <PageTitle title={"Meal History"} />

        <div className="flex z-50 sticky top-2 items-center justify-between bg-[#FFF4F0] border-[2px] border-orangeAccent rounded-lg px-2 py-2 mb-5">
          <button
            className="flex p-2"
            onClick={() => setCurrentMonth(subMonths(currentMonth, 1))}
          >
            <span class="material-symbols-rounded text-orangeAccent">
              arrow_back_ios
            </span>
          </button>
          <h2 className="flex gap-2 items-center text-blackAccent font-semibold">
            <span class="material-symbols-rounded text-orangeAccent">
              calendar_month
            </span>
            {currentMonth?.toUTCString().slice(8, 16)}
          </h2>
          <button
            className="flex p-2"
            onClick={() => setCurrentMonth(addMonths(currentMonth, 1))}
          >
            <span class="material-symbols-rounded text-orangeAccent">
              arrow_forward_ios
            </span>
          </button>
        </div>

        <div className="flex h-auto relative">
          <div className="sticky max-h-[480px] top-[20%] border-right-[2px] border-red-500 w-[20%] border-r-[2px] border-r-[#DADADA] mb-2">
            <span className="material-symbols-rounded block text-center text-orangeAccent mb-2">
              expand_less
            </span>
            <div className="overflow-y-scroll min-h-[200px] h-full max-h-[380px] no-scrollbar" ref={dateSelectorsRef} id="date-scroller">
              {days.map((item, index) => (
                <button
                  id={selectedDate?.toDateString() === item.date?.toDateString() ? 'date-selected' : ''}
                  className={`${selectedDate?.toDateString() === item.date?.toDateString()
                    ? "block border-[2px] w-[42px] h-[42px] m-auto mb-4 rounded-md text-blackAccent text-[1rem] border-orangeAccent bg-[#FFF4F0]"
                    : "block border-[2px] w-[42px] h-[42px] m-auto mb-4 rounded-md text-blackAccent text-[1rem] border-blackAccent"
                    }`}
                  onClick={() => handleDateChange(item.date)}
                >
                  {item.day}
                </button>
              ))}
            </div>
            <span class="material-symbols-rounded block text-center text-orangeAccent mt-2">
              expand_more
            </span>
          </div>
          <div className="h-full w-[80%] px-4 mb-6 flex flex-col items-start justify-center overflow-y-auto py-5">
            {
              mealsData?.map((data, index) => (
                <div className="border-b border-dashed border-b-grey-200 w-full flex flex-col py-3">
                  <div className="flex flex-row justify-between w-full text-black">
                    <small>{data?.orderDate}</small>
                    <small className={`capitalize ${data?.status === 'cancelled' ? 'text-red-400' : ''}`}>Status: {data?.transactionId ? data?.status : <span className="text-red-400 font-semibold">Order Failed</span>}</small>
                  </div>
                  <div className="flex flex-row justify-between w-full mb-4 text-grey-500 gap-x-2">
                    <p>Order #{data?._id?.substring(data?._id?.length - 6)}</p>
                    <p className="capitalize">{data?.mealType}</p>
                  </div>
                  {data?.meals?.map((d, i) => (
                    <FoodItem key={d?.mealName + index} mealName={d?.mealName} mealType={d?.mealType} mealCost={d?.mealCost} qty={d?.meals[i]?.qty} />
                  ))}
                  <div className="flex flex-row justify-between w-full text-black">
                    {data?.transactionId && data?.status !== 'cancelled' ? <small>OTP: {data?.otp}</small> : null}
                    {data?.transactionId && data?.status !== 'cancelled' ? <button className="text-red-400 font-semibold" onClick={()=>markOrderCancel({orderId: data?._id})}>Cancel Order</button> : null}
                  </div>
                </div>
              ))
            }

            {
              mealsData?.length === 0 ? <p className="w-full text-center text-grey-400">
                No Meals Found!
              </p> : null
            }
          </div>
          <div className="fixed bg-white bottom-[4.5em] left-0 w-[100%] flex py-3 px-4 items-center justify-between border-t-[2px] border-t-[#DADADA]">
            <p className="text-[#343741] text-[14px]">Day's Total</p>
            <p className="text-orangeAccent font-medium text-[14px]">₹{mealsData?.reduce((accumulator, currentValue) => accumulator + currentValue?.mealCost || 0 * currentValue?.meals[0]?.qty || 0, 0)}</p>
          </div>
        </div>
      </div>
      {/* <Navbar /> */}
    </>
  );
}

export default MealsPage;
