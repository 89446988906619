import React, { useEffect, useRef, useState } from "react";
import PageTitle from "../../components/PageTitle/PageTitle";
import { useDispatch } from "react-redux";
import { getMealList } from "../../redux/actions/MealActions";
import SolidButton from "../../components/Buttons/SolidButton";
import { initPurchase, placeOrder, stripePay } from "../../services/api/account";
import { createRazorpayPayment } from "../../redux/actions/TenantActions";
import toast from "react-hot-toast";

function FoodItem({ mealName, mealCost, mealType, quantity, onQuantityChange, onRemove, mealId }) {
  return (
    <div className="flex w-full mb-4">
      <div className="w-[64px] h-[64px] bg-orange-50 rounded-md p-3">
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="opacity-100">
          <mask id="mask0_560_1270" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
            <rect width="24" height="24" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_560_1270)">
            <path d="M4 21L2 19H22L20 21H4ZM3 18C3.1 17.7 3.23333 17.4167 3.4 17.15C3.56667 16.8833 3.76667 16.6333 4 16.4V9H3V7.5H4V6.75H3V5.25H4V4.5H3V3H10C10.55 3 11.0208 3.19583 11.4125 3.5875C11.8042 3.97917 12 4.45 12 5V5.25H21V6.75H12V7C12 7.55 11.8042 8.02083 11.4125 8.4125C11.0208 8.80417 10.55 9 10 9H8V15.1C8.23333 15.1333 8.46667 15.1833 8.7 15.25C8.93333 15.3167 9.15 15.4167 9.35 15.55C9.78333 14.4667 10.475 13.6042 11.425 12.9625C12.375 12.3208 13.425 12 14.575 12C16.075 12 17.3542 12.5125 18.4125 13.5375C19.4708 14.5625 20 15.8167 20 17.3V18H3ZM8 5.25H10V4.5H8V5.25ZM8 7.5H10V6.75H8V7.5ZM5.5 5.25H6.5V4.5H5.5V5.25ZM5.5 7.5H6.5V6.75H5.5V7.5ZM5.5 15.35C5.66667 15.2667 5.82917 15.2042 5.9875 15.1625C6.14583 15.1208 6.31667 15.0833 6.5 15.05V9H5.5V15.35Z" fill="#FE5935" />
          </g>
        </svg>
      </div>
      <div className="flex flex-row justify-between w-full">
        <div className="px-2">
          <div className="flex flex-row items-center justify-center gap-x-2">
            <div className={`h-2 w-2 rounded-full ${mealType === 'veg' ? 'bg-green-400' : 'bg-red-400'}`} />
            <h3 className="text-[#343741] text-16 font-normal">{mealName}</h3>
          </div>
          <h5 className="text-[#343741] text-16 font-semibold ml-auto">₹{mealCost}</h5>
        </div>
        <div className="flex flex-row items-center">
          <button onClick={() => onRemove(mealId, mealCost)} className="text-[#FE5935] font-bold text-16 focus:outline-none bg-grey-50 p-3">
            -
          </button>
          <span className="mx-2 text-20">{quantity}</span>
          <button onClick={() => onQuantityChange(mealId, mealCost)} className="text-[#FE5935] font-bold text-16 focus:outline-none bg-grey-50 p-3">
            +
          </button>

        </div>
      </div>
    </div>
  );
}

function AlaCartePage() {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [cartArray, setCartArray] = useState([]);

  const handleQuantityChange = (mealId, mealCost) => {
    const existingItem = cartArray.find(item => item.mealId === mealId);
    if (existingItem) {
      const updatedCart = cartArray.map(item => {
        if (item.mealId === mealId) {
          return { ...item, qty: item.qty + 1 };
        }
        return item;
      });
      setCartArray(updatedCart);
    } else {
      const newItem = { mealId: mealId, qty: 1, mealCost };
      setCartArray([...cartArray, newItem]);
    }
  };

  const handleRemoveItem = (mealId) => {
    const updatedCart = cartArray.map(item => {
      if (item.mealId === mealId) {
        const updatedQty = item.qty - 1;
        if (updatedQty <= 0) {
          return null;
        } else {
          return { ...item, qty: updatedQty };
        }
      }
      return item;
    }).filter(Boolean);
    setCartArray(updatedCart);
  };

  const getTotalCost = () => {
    return cartArray.reduce((total, item) => total + item.mealCost * item.qty, 0);
  };

  const getMealsForAlaCarte = async () => {
    const { data, success } = await dispatch(getMealList(true));
    if (success) {
      setItems(data);
    }
  };

  const placeOrderAndPay = async () => {
    const { data: { data, success, txnId } } = await placeOrder({
      mealIdArray: cartArray?.map(({ mealId, qty }) => ({ mealId, qty })),
      orderType: 'alaCarte',
      mealType: ''
    });

    if (success) {
      await dispatch(createRazorpayPayment(getTotalCost(), txnId, { isWallet: false, planId: null, planName: null, redirectionURL: 'meals', paymentDescription: 'Order Placement' }));
    }
  }

  useEffect(() => {
    getMealsForAlaCarte();
  }, []);

  return (
    <>
      <div className="relative h-full p-4 pb-20">
        <PageTitle title={"Ala Carte Menu with Extras"} />
        <div className="flex h-auto relative">
          <div className="h-full w-full px mb-6 flex flex-col items-center justify-center overflow-y-auto py-5">
            {items?.map((item, index) => (
              <FoodItem
                key={item._id}
                mealId={item._id}
                mealName={item.name}
                mealType={item.type}
                mealCost={item.mealCost}
                quantity={cartArray.find(cartItem => cartItem.mealId === item._id)?.qty || 0}
                onQuantityChange={handleQuantityChange}
                onRemove={handleRemoveItem}
              />
            ))}
          </div>
          <div className="fixed bg-white bottom-[4.5em] left-0 w-[100%] flex py-3 px-4 items-center justify-between border-t-[2px] border-t-[#DADADA]">
            {process.env.REACT_APP_FEATURE_FLAG === 'true' ? <p className="text-14 text-center">You can place your orders from 1st May 2024</p> : null}
            <p className="text-[#343741] text-[14px]">Grand Total:<span className="text-orangeAccent font-bold text-[14px] ml-1">₹{getTotalCost()}</span></p>
            <SolidButton text={'Place Order'} className={'text-14'} disabled={getTotalCost() === 0} onClick={() => process.env.REACT_APP_FEATURE_FLAG === 'true' ? toast.error('You can place your orders from 1st May - Stay tuned!') : getTotalCost() !== 0 ? placeOrderAndPay() : null} />
          </div>
        </div>
      </div>
    </>
  );
}
export default AlaCartePage;