import { callBackend } from "../http.service";

export const loginWithPassword = async(email, password) => {
    return await callBackend("GET", `user/user-login?email=${email}&password=${password}`);
}
// fetch user data
export const fetchUserProfile = async () => {
    return await callBackend("GET", `user/get-profile`);
}
// update user data
export const updateProfile = async (updatedData) => {
    return await callBackend("PUT", `user/update-profile`, { updatedData });
}

// plans
export const fetchPlans = async () => {
    return await callBackend('GET', 'user/get-plans');
}

// payment
export const initPurchase = async (data) => {
    return await callBackend("POST", "transaction/initiate-transaction", data);
};

export const placeOrder = async (data) => {
    return await callBackend("POST", "user/place-order", data);
};

export const fetchWalletBalance = async () => {
    return await callBackend("GET", `wallet/get-wallet-balance`);
}

export const fetchWalletHistory = async () => {
    return await callBackend("GET", `transaction/get-transaction-history`);
}

// stripe
export const stripePay = async (amount, transactionId, metaInformation) => {
    var data = {
        amount,
        transactionId,
        metaInformation
    };

    return await callBackend("POST", `pay/stripe/payment`, data);
};

// razorpay
export const payRazorpay = async (amount, transactionId) => {
    var data = {
        amount: String(amount),
        transactionId,
    };

    return await callBackend("POST", `pay/razorpay/payment`, data);
};

export const  razorpayCallBack = async (
    orderId,
    planId,
    paymentAgenda,
    isWallet,
    rzpOrderId,
    paymentId,
    rzpSignature,
    amount
) => {
    var data = {
        orderId,
        planId,
        paymentAgenda,
        isWallet,
        rzpOrderId,
        paymentId,
        amount
    };
    //
    return await callBackend("POST", `razorpay/callback`, data, {
        "x-razorpay-signature": rzpSignature,
    }, true);
};