import React, { useEffect, useRef, useState } from "react";
import PageTitle from "../../components/PageTitle/PageTitle";
import { useDispatch } from "react-redux";
import WalletBalance from "../../components/Wallet/WalletBalance";
import { getWalletBalance, getWalletHistory } from "../../redux/actions/TenantActions";
import WalletTransactionContainer from "../../components/Wallet/WalletTransactionContainer";

function WalletPage() {
  const dispatch = useDispatch();

  // wallet transaction
  useEffect(() => {
    dispatch(getWalletBalance())
    dispatch(getWalletHistory())
  }, []);

  return (
    <>
      <div className="min-h-screen p-4 pb-20">
        <PageTitle title={"Wallet"} />

        <div className="flex h-auto relative">
          <div className="h-full w-full px-4 mb-6 flex flex-col items-start justify-center overflow-y-auto py-5">
            <WalletBalance />
            <div className="mt flex flex-col w-full">
              <h4 className="text-20 font-semibold">History</h4>
              <WalletTransactionContainer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WalletPage;
